<template>
  <div class="loginIndex" :style="systemConfig.VUE_APP_LOGIN_BG_IMG">
    <el-row style="height: 25%" type="flex" align="middle">
      <el-col v-on:span="24" class="title_text">
        <!-- <img
          :src="systemConfig.VUE_APP_LOGIN_NAME_IMG"
          :style="systemConfig.VUE_APP_LOGIN_NAME_IMG_STYLE"
          alt
        /> -->
        雪迪龙视频学习系统
      </el-col>
    </el-row>
    <el-row type="flex" style="height: 65%">
      <el-col class="screen_bg" :span="12">
        <img :src="systemConfig.VUE_APP_LOGIN_BG_CENTER_IMG" alt />
      </el-col>
      <el-col :span="12" class="loginbox">
        <div class="login">
          <div style="height: 360px" v-if="!downDivShow">
            <el-form
              label-position="top"
              ref="loginForm"
              :model="form"
              :hide-required-asterisk="true"
              :rules="rules"
              label-width="80px"
              class="login_box"
            >
              <h3 class="login_title">登录</h3>
              <el-form-item prop="username">
                <div class="labelTxt">用户</div>
                <el-input type="text" placeholder v-model="form.username" />
              </el-form-item>
              <el-form-item prop="password">
                <div class="labelTxt">密码</div>
                <el-input
                  type="password"
                  placeholder="*********"
                  v-model="form.password"
                />
              </el-form-item>
              <el-form-item style="text-align: right" prop="checked">
                <el-checkbox v-model="form.checked">记住密码</el-checkbox>
              </el-form-item>
              <el-form-item style="text-align: center">
                <el-button
                  type="primary"
                  class="login_button"
                  size="medium"
                  @click="onSubmit('loginForm')"
                  :loading="loading"
                  >登录</el-button
                >
                <div class="countryBox"></div>
              </el-form-item>
            </el-form>
          </div>
          <div style="height: 360px" v-if="downDivShow" class="zhanghuLogin">
            <img
              @click="goLogin"
              class="backlogin"
              src="../../src/assets/img/backlogin.png"
              alt
            />
            <p class="txt">手机扫码，安全下载</p>
            <div class="qrcode">
              <img :src="qrcodeImg" alt="" />
            </div>
            <p class="txt2">
              打开<span style="color: #409eff">手机</span>扫一扫，轻松下载APP
            </p>
          </div>
          <!-- <div class="downDiv">
            <img src="../../src/assets/img/down.png" alt="" />
            <p class="downTxt" @click="downBtn">快速安全APP下载</p>
          </div> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import aesutil from "@/utils/AESUtil";
import _ from "lodash";
import { configMap, setDocumentTitle } from "@/config/systemConfig";
export async function loginSuccess(data, $store = this.$store) {
  // console.log(data)
  await $store.dispatch("getPowerMenus"); // 获取权限按钮
}
export default {
  name: "loginIndex",
  data() {
    return {
      form: {
        username: "",
        password: "",
        checked: false
      },
      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      rules: {
        username: [
          { required: true, message: "账号不可为空", trigger: "blur" }
        ],
        password: [{ required: true, message: "密码不可为空", trigger: "blur" }]
      },
      loading: false,
      downDivShow: false,
      qrcodeImg: "",
      firstPath: {},
      systemConfig: {}
    };
  },
  created() {
    // localStorage.clear();
    // 同一个页面切换不同地址登录时去掉之前存储的标识
    sessionStorage.clear();
    this.setSystemConfig();
    // 一定要在create里面执行一下写的回车事件enterSearch(),否则会执行不了
    this.enterSearch();
    // this.getQrCode();
    let username = this.getCookie("usernameS");
    let password = aesutil.decrypt(this.getCookie("passwordS"));
    sessionStorage.setItem("alarmReminder", true); // 设置提醒报警
    // 如果存在赋值给表单，并且将记住密码勾选
    if (username) {
      this.form.username = username;
      this.form.password = password;
      this.form.checked = true;
    }
  },
  methods: {
    // 回车登录
    enterSearch() {
      document.onkeydown = e => {
        // 13表示回车键，baseURI是当前页面的地址，为了更严谨，也可以加别的，可以打印e看一下
        if (e.keyCode === 13) {
          // 回车后执行搜索方法
          this.onSubmit("loginForm");
        }
      };
    },
    // 储存表单信息
    setUserInfo: function () {
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (this.form.checked) {
        this.setCookie("usernameS", this.form.username, 30);
        let passWord = aesutil.encrypt(this.form.password);
        this.setCookie("passwordS", passWord, 30);
      } else {
        this.setCookie("usernameS", "");
        this.setCookie("passwordS", "");
      }
    },
    // 获取cookie
    getCookie: function (key) {
      if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          let end = document.cookie.indexOf(";", start);
          if (end === -1) end = document.cookie.length;
          return unescape(document.cookie.substring(start, end));
        }
      }
      return "";
    },
    // 保存cookie
    setCookie: function (cName, value, expiredays) {
      let exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
    },
    getFirstPath(data) {
      if (data[0].children && data[0].children.length) {
        this.getFirstPath(data[0].children);
      } else {
        this.firstPath = data[0];
      }
    },
    onSubmit(formName) {
      let that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          that.loading = true;
          let objs = {
            userAccount: that.form.username,
            userPwd: aesutil.encrypt(that.form.password)
          };
          this.$api("login.loginUser")
            .invoke(objs)
            .then(res => {
              // that.loading = false;
              if (res.data.code === 1) {
                this.setUserInfo();
                let mydata = res.data.data;
                let token = aesutil.decrypt(mydata.rediskey);
                sessionStorage.setItem("userId", mydata.userId);
                sessionStorage.setItem("userName", mydata.userName);
                sessionStorage.setItem("regionname", mydata.regionname);
                sessionStorage.setItem(
                  "regioncodeParent",
                  mydata.regioncodeParent
                );
                sessionStorage.setItem("regioncode", mydata.regioncode);
                sessionStorage.setItem("token", token);
                sessionStorage.setItem("isLoginCheck", 1);
                sessionStorage.setItem("orgID", mydata.orgID);
                sessionStorage.setItem("userAccount", mydata.userAccount);
                // loginSuccess.call(this, data).then(() => {
                //   console.log(data)
                // })
                this.selectByCode(mydata.regioncode);
                // 登录成功设置用户信息到store中
                this.$store.commit("updateUserInfo", mydata);
                that.$store
                  .dispatch("getPowerMenus")
                  .then(async res => {
                    const yjTopMenuData = this.$store.state.menus.yjTopMenuData;
                    if (!yjTopMenuData.length) {
                      that.loading = false;
                      this.$message({
                        showClose: true,
                        message: "用户无任何系统操作权限，请联系系统管理员",
                        type: "error",
                        duration: 3000
                      });
                      return;
                    }
                    // console.log(res)
                    this.getFirstPath(yjTopMenuData);
                    if (res.code == 1) {
                      this.$router.replace({
                        path: this.firstPath.navigateUrl,
                        query: {
                          sysmodel: this.firstPath.sysmodel,
                          moduleName: this.firstPath.moduleName
                        }
                      });
                      // that.loading = false;
                    }
                  })
                  .catch(() => {
                    that.loading = false;
                  });
              } else {
                that.loading = false;
                return this.$message.error(res.data.msg);
              }
            })
            .catch(() => {
              that.loading = false;
            });
        } else {
          that.$alert("账号密码格式不正确", {
            confirmButtonText: "确定",
            // callback: action => {}
            callback: () => {}
          });
          return false;
        }
      });
    },
    // 根据用户行政区划获取经纬度
    selectByCode(regioncode) {
      this.$api("common.selectByCode")
        .invoke({ code: regioncode })
        .then(({ data: { code, data } }) => {
          let mapCenter;
          if (data.longitude && data.latitude) {
            mapCenter = [data.longitude, data.latitude];
          } else {
            mapCenter = [116.236905, 40.226835];
          }
          sessionStorage.setItem("mapCenter", JSON.stringify(mapCenter));
        });
    },
    downBtn() {
      // 显示二维码
      this.downDivShow = true;
    },
    goLogin() {
      // 返回登录弹框
      this.downDivShow = false;
    },
    getQrCode() {
      // 获取APP最新二维码
      this.$api("common.getQrCode")
        .invoke()
        .then(({ data: { data } }) => {
          this.qrcodeImg = data.data;
        });
    },
    setSystemConfig() {
      const fullPath = this.$route.fullPath;
      const index = fullPath.indexOf("_");
      let systemName;
      if (index > -1) {
        systemName = fullPath.substr(index + 1, fullPath.length);
      } else {
        systemName = "default";
      }
      sessionStorage.setItem("systemName", systemName);
      this.systemConfig = configMap.get(systemName);
      setDocumentTitle();
    }
  }
};
</script>
<style scoped lang="less">
.loginIndex {
  width: 100%;
  height: 100%;
  min-width: 1024px;
  // background: url("../../src/assets/img/bj1.jpg") no-repeat center center;
  // background-size: 100%;
  // background-image: url("../../src/assets/img/bj1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  .el-form-item {
    margin-bottom: 12px;
  }
  .labelTxt {
    font-size: 16px;
    font-weight: 500;
    color: #034f61;
    line-height: 30px;
  }
  .title_text {
    font-size: 48px;
    color: #f1f5f5;
    margin: 0 auto;
    text-align: center;

    img {
      width: 580px;
    }
  }

  .screen_bg {
    text-align: right;
    margin-right: 50px;
    img {
      height: 456px;
    }
  }
  .loginbox {
    margin: 0 auto;
    .countryBox {
      text-align: right;
      line-height: 1.1;
      margin-top: 10px;
    }
  }
  .login {
    width: 350px;
    margin-left: 50px;
    margin-top: 10px;
    background: #f6fbff;
    border: 0.15rem solid #4a93eb;
    border-radius: 0.15rem;
    float: left;
    margin-right: 100px;
    .login_box {
      padding: 0.2rem;
    }
    .login_title {
      font-size: 0.26rem;
      color: #034f61;
      text-align: center;
      position: relative;
      letter-spacing: 5px;
      margin-bottom: 10px;
    }
    .login_title:after,
    .login_title:before {
      background: #d4dde7;
      content: "";
      height: 1px;
      position: absolute;
      top: 50%;
      width: 33%;
    }
    .login_title:after {
      left: 5%;
    }
    .login_title:before {
      right: 5%;
    }
    .login_button {
      width: 100%;
      color: #fff;
      background-color: #2866f7;
      font-size: 0.2rem;
      letter-spacing: 6px;
    }
    .downDiv {
      text-align: center;
      padding-bottom: 10px;
      .downTxt {
        font-size: 14px;
        color: #e6a23c;
        cursor: pointer;
        display: inline-block;
      }
      img {
        width: 18px;
        height: 15px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        top: 3px;
      }
    }
    .zhanghuLogin {
      position: relative;
      padding-top: 70px;
      text-align: center;
      .backlogin {
        width: 168px;
        height: 39px;
        display: block;
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 12px;
      }
      .txt {
        padding-bottom: 40px;
      }
      .qrcode {
        width: 127px;
        height: 127px;
        margin: 0 auto;
        box-sizing: content-box;
        box-shadow: 0 0 6px #ccc;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          display: block;
          cursor: pointer;
          image-rendering: -moz-crisp-edges;
          image-rendering: -o-crisp-edges;
          image-rendering: -webkit-optimize-contrast;
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor;
        }
      }
      .txt2 {
        padding-top: 40px;
      }
    }
  }
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  .loginIndex .login {
    width: 70%;
  }
  .loginIndex .login .el-form-item {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 1441px) and (min-width: 1365px) {
  .loginIndex .login {
    width: 60%;
  }
}
</style>
